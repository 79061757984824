<!--header-logo
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-04-25 13:21:30
 * @LastEditTime: 2022-12-28 15:21:40
-->
<template>
  <div class="header">
    <img @click="pushTo('social')" class="header-logo" src="@/assets/logo.png" alt="logo" />
    <span class="header-mini" v-if="title">{{ title }}</span>
    <img class="header-menu" src="@/assets/h5/menu.png" alt="menu" @click="showPopup" />
  </div>

  <van-popup v-model:show="show" position="bottom" :style="{ height: '3.65rem' }" teleport="body">
    <div style="position: relative; height: 100%">
      <div class="H5_btns">
        <div @click="pushTo('social')">
          <div class="s_icon"></div>
          <div class="s_title">社会招聘</div>
        </div>
        <div @click="pushTo('campus')">
          <div class="s_icon"></div>
          <div class="s_title">校园招聘</div>
        </div>
        <div @click="pushTo('blue')">
          <div class="s_icon"></div>
          <div class="s_title">一线招聘</div>
        </div>
        <div @click="pushTo('my')">
          <div class="s_icon"></div>
          <div class="s_title">我的</div>
        </div>
      </div>
      <div class="cancel" @click="show = false">取消</div>
    </div>
  </van-popup>
</template>

<script lang="js">
import { defineComponent, ref, toRefs, onMounted, getCurrentInstance, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'

export default defineComponent({
  props: {
    recruitType: Number
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { recruitType } = toRefs(props)

    const router = useRouter()
    const route = useRoute()

    const title = ref('')
    const show = ref(false)

    onBeforeRouteLeave((to, from, next) => {
      show.value = false
      next()
    })

    watch(recruitType, (nv, ov) => {
      getTitle(nv)
    })

    const getTitle = (type) => {
      if (type) {
        title.value = proxy.$Global.recruiteType2String[type]
      } else if (route && route.meta.recruitType) {
        title.value = proxy.$Global.recruiteType2String[+route.meta.recruitType]
      }
    }

    const showPopup = () => {
      show.value = true
    }

    function pushTo(val) {
      if (val == 'social') {
        router.push({ path: '/' })
      } else if (val == 'campus') {
        router.push({ name: 'campus' })
      } else if (val == 'blue') {
        router.push({ name: 'blue' })
      } else {
        router.push({ name: 'my' })
      }
      sessionStorage.removeItem('positionKeepAlive')
    }

    getTitle()
    return {
      show,
      title,
      showPopup,
      open,
      pushTo
    }
  }
})
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 0.7rem;
  background: #fff;
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 9;
  color: #fff;
  padding: 0 0.1rem 0 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  .header-menu {
    margin-right: 0.1rem;
    margin-left: auto;
    height: 100%;
  }
  .header-logo {
    height: 50%;
    margin-left: 0.1rem;
    // filter: brightness(100);
  }
  .header-logo__mini {
    height: 50%;
    margin-left: 0.03rem;
    // filter: brightness(100);
  }
  .header-mini {
    background: #3693ff;
    color: #fff;
    font-size: 0.2rem;
    padding: 0.08rem 0.1rem;
    border-radius: 0.1rem;
    margin-left: 0.03rem;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    line-height: 0.2rem;
    box-sizing: border-box;
  }
}
::v-deep .van-popup--bottom {
  border-radius: 0.63rem 0.63rem 0rem 0rem;
}
.H5_btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.44rem 0.58rem 0;
  box-sizing: border-box;
  .s_icon {
    width: 1rem;
    height: 1rem;
    margin: 0 auto;
  }
  .s_title {
    font-size: 0.29rem;
    color: #666666;
    margin-top: 0.25rem;
    text-align: center;
  }

  & > div:nth-child(1) {
    .s_icon {
      background: url(~@/assets/h5/social.png) no-repeat;
      background-size: cover;
    }
  }
  & > div:nth-child(2) {
    .s_icon {
      background: url(~@/assets/h5/school.png) no-repeat;
      background-size: cover;
    }
  }
  & > div:nth-child(3) {
    .s_icon {
      background: url(~@/assets/h5/social.png) no-repeat;
      background-size: cover;
    }
  }
  & > div:nth-child(4) {
    .s_icon {
      background: url(~@/assets/h5/my.png) no-repeat;
      background-size: cover;
    }
  }
}
.cancel {
  position: absolute;
  width: 7.15rem;
  height: 0.83rem;
  background: #f6f7fc;
  border-radius: 0.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0.33rem;
  color: #83888d;
  text-align: center;
  line-height: 0.83rem;
  bottom: 0.17rem;
}
</style>
