/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-13 10:23:39
 * @LastEditTime: 2024-01-11 10:27:31
 */
import axios from 'axios'
// import { ElMessage } from 'element-plus'
import { ZMessage } from '@/utils/confirm'
import { HostName, UploadHostName } from '@/config/env'
import router from '@/router/index'

// 创建一个axios实例
const service = axios.create({
  baseURL: `${HostName}`,
  withCredentials: true
})

service.interceptors.request.use(
  (config: any) => {
    if (config.urlHost == 'dfs') {
      config.baseURL = UploadHostName
    }
    if (localStorage.getItem('Authorization')) {
      config.headers['Authorization'] = localStorage.getItem('Authorization')
    }
    if (window.location.hostname == 'localhost') {
      // config.headers['x-dubbo-tag'] = 'szxtv6230dev'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (!res.status) {
      // 403用户未登录
      // REC000009 登录已过期，请重新登录!
      if (res.statusCode === '403' || res.statusCode === 'REC000009') {
        if (response.config.url != '/website/post/getWhetherSend') {
          ZMessage({
            message: res.message,
            type: 'error'
          })
        }
        router.push({
          name: 'Login'
        })
      } else {
        console.log(`response.config.url`, response.config.url)
        if (response.config.url != 'wechat/shareConfig') {
          ZMessage({
            message:
              response.config.url != 'website/resume/parse' ? res.message : `${res.message}, 请重新点击[解析]按钮！`,
            type: 'error'
          })
        }
      }
      return Promise.reject(res)
    } else {
      return res.result
    }
  },
  (error) => {
    ZMessage({
      message: error.message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
