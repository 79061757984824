/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-11 16:36:45
 * @LastEditTime: 2023-07-14 15:19:54
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import Vant from 'vant'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import Global from './config/global'
import App from './App.vue'
import router from './router'
import api from '@/services'
import store from '@/store'
import dayjs from 'dayjs'
// import load from '@/utils/loading'
// import loading from '@/plugins/loading'
import plugins from '@/plugins'
import isMobile from '@/utils/judgeH5'
import wechatShare from '@/utils/wxShare2'
import 'element-plus/dist/index.css'
import 'font-awesome/css/font-awesome.min.css'
import '@/styles/index.scss'
// import 'default-passive-events'
import 'vant/lib/index.css'
import '@/utils/rem.js'
import { Env } from '@/config/env'

import ZHeader from '@/components/layout/z-header.vue'
import ZFooter from '@/components/layout/z-footer.vue'

const app = createApp(App)

app
  .component('ZHeader', ZHeader)
  .component('ZFooter', ZFooter)
  .use(router)
  .use(store)
  .use(ElementPlus, {
    locale
  })
  .use(Vant)
  .use(plugins)
// 全局挂载
app.config.globalProperties.$Global = Global
app.config.globalProperties.$http = api
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$isMobile = isMobile
;(window as any).zcat.init({ appKey: 'msd-zp-pc', vue: app, isProd: Env === 'PROD' })

wechatShare({
  title: '欢迎您加入中通快递',
  desc: '用热爱成就更多精彩',
  link: location.href,
  imgUrl: 'https://hr.zto.com/share.jpg'
})

app.mount('#app')
