/*
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-14 14:38:45
 * @LastEditTime: 2023-04-27 17:06:14
 * @Description:
 */
import wx from 'weixin-js-sdk'
import api from '@/services'

function wechatShare(param) {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') != -1
  if (isWeixin) {
    api.base.getShareConfig({ url: encodeURI(window.location.href) }).then((res) => {
      const { appId, nonceStr, signature, timestamp } = res
      const jsApiList = [
        'checkJsApi',
        'updateTimelineShareData',
        'updateAppMessageShareData',
        'onMenuShareAppMessage', //老版本分享接口(注意这两个旧版本的必须要放的不然会有问题)
        'onMenuShareTimeline' //老版本分享接口。
      ]

      // wx.config
      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList
      })
    })

    // wx.ready
    wx.ready(() => {
      console.log(`wx.ready`)

      const { title, desc, link, imgUrl } = param

      const tempParam = {
        title,
        desc,
        link,
        imgUrl,
        success: (res) => {
          console.log(`分享成功`, res)
        },
        cancel: (err) => {
          console.log('分享失败', err)
        }
      }
      // 分享给朋友
      wx.onMenuShareAppMessage(tempParam)
      // 分享到朋友圈
      wx.onMenuShareTimeline(tempParam)
      // 分享给朋友 + 分享到QQ
      wx.updateAppMessageShareData(tempParam)
      // 分享到朋友圈 + 分享到QQ空间
      wx.updateTimelineShareData(tempParam)
    })

    // wx.error
    wx.error((err) => {
      console.log('wechat config失败结果', err)
    })
  }
}
export default wechatShare
