
import { defineComponent } from 'vue'

export default defineComponent({
  props: { titleName: String },
  setup(props) {
    return {
      props
    }
  }
})
