/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-18 09:59:47
 * @LastEditTime: 2023-06-28 17:39:42
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    userInfo: {},
    // 简历信息
    resumeInfo: null,
    // 登录后redirect
    redirectUrl: null,
    // 保存resumeType
    resumeType: localStorage.getItem('resumeType') ? localStorage.getItem('resumeType') : null,
    isFreeLogin: false
  },
  mutations: {
    SET_AUTHORIZATION(state, params) {
      state.Authorization = params
      localStorage.setItem('Authorization', params)
    },
    SET_USER_INFO(state, params) {
      state.userInfo = params
    },
    SET_RESUME_INFO: (state, params) => {
      state.resumeInfo = params
    },
    SET_REDIRECT_URL: (state, params) => {
      state.redirectUrl = params
    },
    SET_RESUME_TYPE: (state, params) => {
      state.resumeType = params
      console.log('更改')
      localStorage.setItem('resumeType', params)
    },
    SET_IS_FREE_LOGIN: (state, params) => {
      state.isFreeLogin = params
    }
  },
  actions: {
    setAuthorization(context, params) {
      context.commit('SET_AUTHORIZATION', params)
    },
    setUserInfo(context, params) {
      context.commit('SET_USER_INFO', params)
    },
    setResumeInfo(context, params) {
      context.commit('SET_RESUME_INFO', params)
    },
    setRedirectUrl(context, params) {
      context.commit('SET_REDIRECT_URL', params)
    },
    setResumeType(context, params) {
      context.commit('SET_RESUME_TYPE', params)
    },
    setIsFreeLogin(context, params) {
      context.commit('SET_IS_FREE_LOGIN', params)
    }
  },
  modules: {}
})
