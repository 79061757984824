/*
 * @Author: lusaiwen913-tel:17596122336
 * @Version: 2.0
 * @Date: 2022-10-26 09:15:36
 * @LastEditTime: 2022-11-18 10:34:45
 * @Description: loginFree
 */
export default {
  //获取候选人信息
  getResumeInfo: {
    url: 'candidate/allInfo',
    method: 'post'
  },
  //更新简历
  updateResumeInfo: {
    url: 'candidate/resume/update',
    method: 'post'
  }
}
