/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-15 18:35:53
 * @LastEditTime: 2022-03-16 10:10:05
 */

const ua = window.navigator.userAgent.toLowerCase()

const isMobile = /(iphone|ipod|ios|ipad|android|backerry|webos|symbian|windows phone|phone|micromessenger)/i.test(ua)

export default isMobile
