
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  computed
} from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { useRoute, useRouter } from 'vue-router'
import 'swiper/swiper-bundle.min.css'
import store from '@/store'
import { Right } from '@element-plus/icons'

interface stateOption {
  mySwiper: any
  storyList: any
  swiperOps: any
  h5More: boolean
  allStoryList: any
}

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    Right
  },
  props: {
    type: String,
    recruitingType: Number,
    isSingle: Boolean
  },
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const { type } = toRefs(props)
    const route = useRoute()
    const state = reactive({
      mySwiper: null,
      storyList: [],
      swiperOps: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        loop: true,
        speed: 1000,
        pagination: {
          clickable: true
        }
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'custom',
        //   renderCustom: function (swiper, current, total) {
        //     var customPaginationHtml = ''
        //     for (var i = 0; i < total; i++) {
        //       if (i == current - 1) {
        //         customPaginationHtml += '<div class="active"></div>'
        //       } else {
        //         customPaginationHtml += '<div></div>'
        //       }
        //     }
        //     return '<div class="indicator">' + customPaginationHtml + '</div>'
        //   }
        // }
      },
      h5More: false,
      allStoryList: []
    }) as stateOption

    onMounted(() => {
      getSwiperList()
    })
    // 初始化数据
    const initState = () => {
      state.mySwiper = proxy.$refs.mySwiper
    }

    const getSwiperList = () => {
      let data = {
        type: '2',
        recruitingType: props.recruitingType,
        pageNum: 1,
        pageSize: 100,
        status: 1
      }
      proxy.$http.campus.getPCActivityByPage(data).then((res: any) => {
        if (res && res.list) {
          state.storyList = res.list
          state.h5More = false
        }
      })
    }

    function pushToDetail(id) {
      router.push({ name: 'storyDetail', query: { id } })
    }

    function more() {
      state.storyList = state.allStoryList
      state.h5More = false
    }
    initState()

    return {
      ...toRefs(state),
      pushToDetail,
      more
    }
  }
})
