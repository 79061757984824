/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-21 12:52:05
 * @LastEditTime: 2023-06-29 10:26:35
 * 封装常用工具函数,包含对原生基本类型的扩展
 */
import cryptoJs from 'crypto-js'
export const util = {
  /**
   * @description : 生成文件名
   * @result : 返回：d1318ae2-a830-43ae-a5e6-d35022e6959c
   **/
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0
      let v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },

  /**
   * @description : 处理url参数
   * @result : 传参 url?state=1&code=1
   * @result : 返回对象 { state: 1, code: 1 }
   **/
  param2Obj(url) {
    const search = url.split('?')[1]
    if (!search) {
      return {}
    }
    return JSON.parse(
      '{"' +
        decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
        '"}'
    )
  },

  /**
   * @description : 处理resume数据结构
   * @result : 返回单项用来新增的结构
   * @result : 比如说educationExperiences
   * @result : 处理前：
   * educationExperiences: {
      key: 'educationExperiences',
      value: '教育经历',
      children: [
        { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
        { label: '学校名称', value: 'schoolName' },
        { label: '专业名称', value: 'profession' },
        {
          label: '学历',
          value: 'educationTypeValue',
          valueKey: 'educationTypeKey',
          formType: 'select',
          selectKey: 'C_EDU_XUELI'
        },
        { label: '学习形式', value: 'schoolType', valueKey: 'schoolTypeKey', formType: 'select', selectKey: 'LEARN_FORM' }
      ]
    }
   * @result : 处理后：
   * educationExperiences: {
      dateRange: [],
      // 学校
      name: null,
      // 开始时间
      startDate: null,
      // 结束时间
      endDate: null,
      // 学历
      background: null,
      // 专业
      major: null,
      // 专业排名
      majorRank: null,
      // 学习形式
      learnForm: null
    },

   */
  formatForm(obj) {
    const f = {}
    for (const [k, v] of Object.entries(obj)) {
      v.ops = {}
      if (v.children && v.children.length > 0) {
        v.children.forEach((_k) => {
          if (['fileList', 'dateRange'].includes(_k.value)) {
            v.ops[_k.value] = []
          } else {
            v.ops[_k.value] = null
          }
        })
      }
      f[k] = v.ops
    }
    return f
  },

  /**
   * @description : cryptoJs加密
   * @description : 用来处理微信登录
   * @result : 返回一串不知名加密字母数字
   **/
  encryptDes(message, key) {
    const keyHex = cryptoJs.enc.Utf8.parse(key)
    const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 }
    const encrypted = cryptoJs.DES.encrypt(message, keyHex, option)
    return encrypted.ciphertext.toString()
  },

  /**
   * 计算年龄
   *
   * @param {*} birthday 出生日期 "eg: ['1961', '04', '20']"
   */
  getAge(birthday) {
    // 新建日期对象
    let date = new Date()
    // 今天日期，数组，同 birthday
    const nowYear = date.getFullYear()
    const nowMonth = date.getMonth() + 1
    const birthYear = parseInt(birthday[0])
    const birthMonth = parseInt(birthday[1])
    let age = nowMonth >= birthMonth ? nowYear - birthYear : nowYear - birthYear - 1
    age = age > 0 ? age : 0
    return age + ''
  }
}
