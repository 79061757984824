/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-13 10:27:39
 * @LastEditTime: 2024-03-25 15:05:41
 */
interface Property {
  HostName?: string
  UploadHostName?: string
  UploadAppId?: string
  CookieDomain?: string
}
type EnvList = 'PROD' | 'PRE' | 'SIT' | 'TEST' | 'DEV'
// Record<K,T> map the properties of a type to another type
type Url = Record<EnvList, string[]>
type Api = Record<EnvList, Property> & { DEV: Property }

// 域名
const URL: Url = {
  // 生产环境
  PROD: ['https://hr.zto.com', 'http://hr.zto.com'],
  // 预发环境
  PRE: ['http://zp-pc.zpxt.re.ztosys.com'],
  // 集成测试环境
  SIT: [],
  // 测试环境E
  TEST: [
    'http://zto-zp.zpxt.ft.ztosys.com',
    'https://recruiting-gw-test.zt-express.com/',
    'https://msd-zp-pc.test.ztosys.com/',
    'http://10.10',
    'http://192.168',
    'http://localhost'
  ],
  // 开发环境
  DEV: ['http://zto-zp.zpxt.bd.ztosys.com/']
}

// 后台接口的域名
const API: Api = {
  // 生产环境
  PROD: {
    HostName: 'https://recruiting.gw.zt-express.com',
    UploadAppId: 'ztZAHc6UK8XEiJC_mL0vER6A',
    UploadHostName: 'https://newfs.zt-express.com'
  },
  // 预发环境
  PRE: {
    HostName: 'https://recruiting.gw.zt-express.com',
    UploadAppId: 'ztZAHc6UK8XEiJC_mL0vER6A',
    UploadHostName: 'https://newfs.zt-express.com'
  },
  // 集成测试环境
  SIT: {},
  // 测试环境
  TEST: {
    HostName: 'https://recruiting.gw-test.ztosys.com',
    UploadHostName: 'https://fs.test.ztosys.com',
    UploadAppId: 'DFS1402974555929223169'
  },
  // 开发环境
  DEV: {
    HostName: 'https://recruiting.gw-dev.ztosys.com',
    // UploadHostName: 'https://fs.kf.ztosys.com',
    UploadHostName: 'https://fs.test.ztosys.com',
    UploadAppId: 'DFS1402974555929223169'
  }
}

const campusHostName = {
  // 生产环境
  PROD: 'https://zhaopin.zto.com',
  // 预发环境
  PRE: 'https://recruiting-campus.zpxt.re.ztosys.com',
  // 测试环境
  TEST: 'http://base-msd-recruiting-campus-h5-dme.test.ztosys.com'
}

// 后台接口的域名。如模板 HostName 为网关接口。
let HostName = API.TEST.HostName || ''
let UploadHostName = API.TEST.UploadHostName || ''
let UploadAppId = API.TEST.UploadAppId || ''
let CookieDomain = API.TEST.CookieDomain || ''
let Env = 'TEST'
function checkUrl(url: string) {
  return window.location.href.indexOf(url) === 0
}
// 根据域名为HostName重新赋值
Object.keys(URL).some((env) => {
  const urls = URL[<EnvList>env]
  if (urls.length && urls.some(checkUrl)) {
    HostName = API[<EnvList>env].HostName || ''
    UploadHostName = API[<EnvList>env].UploadHostName || ''
    UploadAppId = API[<EnvList>env].UploadAppId || ''
    CookieDomain = API[<EnvList>env].CookieDomain || ''
    Env = env
    return true
  }
})

export { HostName, UploadHostName, UploadAppId, Env, CookieDomain, campusHostName }
