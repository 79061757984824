
import {
  ref,
  toRefs,
  defineComponent,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  watch,
  computed
} from 'vue'
import { ZConfirm } from '@/utils/confirm'
import { useRouter, onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ArrowDown } from '@element-plus/icons-vue'
import { util } from '@/utils/util.js'
import zswitch from '@/components/layout/z-switch.vue'
export default defineComponent({
  components: {
    ArrowDown,
    zswitch
  },
  props: {
    type: String,
    offsetTop: Number,
    recruitType: Number
  },
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { recruitType } = toRefs(props)

    const showSearchComponents = proxy.$Global.showSearchComponents
    const title = ref('')

    const menuActive = ref(recruitType.value)

    const getTitle = (type?: any) => {
      if (type) {
        title.value = proxy.$Global.recruiteType2String[type]
        menuActive.value = +type
      } else if (route && route.meta.recruitType) {
        title.value = proxy.$Global.recruiteType2String[+route.meta.recruitType]
        menuActive.value = +route.meta.recruitType
      }
    }

    // 判断是否登录
    const userIsLogin = ref(localStorage.getItem('Authorization') ? true : false)

    // 当前菜单点亮
    const currentRouteName = ref(router.currentRoute.value.name)

    // 菜单吸顶
    const isFixed = ref(false)

    watch(recruitType, (nv, ov) => {
      getTitle(nv)
    })

    watch(
      () => router.currentRoute.value.path,
      (nv, ov) => {
        if (nv != ov) {
          console.log('nv', nv)
          userIsLogin.value = localStorage.getItem('Authorization') ? true : false
          getUserInfo()
        }
      }
    )

    onBeforeRouteUpdate((to) => {
      console.log(`onBeforeRouteUpdate`)
      getUserInfo()
    })

    function getUserInfo() {
      if (userIsLogin.value && !store.state.userInfo.nickName) {
        proxy.$http.auth
          .getUserInfo()
          .then((res: any) => {
            store.dispatch('setUserInfo', res)
          })
          .catch(() => {
            handleLoginOut()
          })
      }
    }
    function wxLogin() {
      // http://10.10.136.159:8080/?code=021AM1000ytBoN1U7b000KNUC11AM108&state=nABRry#/
      // http://10.10.136.159:8080/#/social?positionType=it-04&code=061euKkl20VNL84TeRkl2THDSn0euKk5&state=nABRry
      // https://recruiting-gw-test.zt-express.com/#/?code=041lTb000DPJwN1oXD100MlhSd3lTb0K&state=eec8e6acafc8dac0017affdc4b0b8fd4
      // https://recruiting-gw-test.zt-express.com/#/social?code=071f2K000GPbwN1t9g1000pPSd3f2K0P&state=ef21d71936e43cae6c528f186cbe02cf
      const { code, state } = util.param2Obj(location.href)
      if (!userIsLogin.value && code && state) {
        const agent = proxy.$isMobile ? 2 : 1
        proxy.$http.auth.wxLogin({ code, state, agent }).then((res: any) => {
          store.dispatch('setAuthorization', res.token)
          userIsLogin.value = true
          let redirectUrl = ''
          if (location.href.includes('&code')) {
            redirectUrl = location.href.split('&code')[0]
          }

          if (location.href.includes('?code')) {
            redirectUrl = location.href.split('?code')[0]
          }
          location.href = redirectUrl
        })
      }
    }
    //判断是否需要
    const hasChange = computed(() => {
      return ['Login'].includes(route.name as string)
    })
    function handleLogoClick() {
      if (currentRouteName.value == 'Home') {
        router.go(0)
      } else {
        router.push({
          name: 'Home'
        })
      }
      sessionStorage.removeItem('positionKeepAlive')
    }
    function checkImage() {
      let arr = ['campus', 'CampusPosition', 'ecologyDetail', 'lecture']
      let isRoute = arr.includes(route.name as any)
      let isquery = route.query.t == 'c'
      if (isRoute || isquery) {
        return true
      } else {
        false
      }
    }

    // 这里不放mounted里确保加载前先执行
    !proxy.$isMobile && wxLogin()

    getUserInfo()

    // 登录
    function toLogin() {
      store.dispatch('setRedirectUrl', route.fullPath)
      router.push({
        name: 'Login'
      })
    }

    // 退出
    function loginOut() {
      ZConfirm('确认退出？', 'warning').then(() => {
        handleLoginOut()
        // router.push({
        //   path: '/'
        // })
        location.href = location.origin
      })
    }

    function handleLoginOut() {
      localStorage.removeItem('Authorization')
      store.dispatch('setUserInfo', {})
      userIsLogin.value = false
    }

    function handleSearchChange(value: string) {
      router.push({
        name: 'social',
        query: {
          search: value
        }
      })
    }

    function toPage(name: string) {
      router.push({
        name
      })
      sessionStorage.removeItem('positionKeepAlive')
    }

    getTitle()

    return {
      isFixed,
      userIsLogin,
      currentRouteName,
      showSearchComponents,
      store,
      toLogin,
      loginOut,
      toPage,
      handleSearchChange,
      handleLogoClick,
      hasChange,
      checkImage,
      title,
      menuActive
    }
  }
})
