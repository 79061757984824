/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-03-20 16:51:04
 * @LastEditTime: 2022-03-20 17:04:18
 */
import { ElMessage, ElMessageBox } from 'element-plus'
import { Toast, Dialog } from 'vant'
import isMobile from '@/utils/judgeH5'

export function ZMessage({ message, type }) {
  if (!isMobile) {
    ElMessage({
      message,
      type,
      duration: 5 * 1000,
      showClose: true
    })
  } else {
    if (type == 'error') {
      Toast.fail(message)
    }
    if (type == 'success') {
      Toast.success(message)
    }
  }
}

export function ZConfirm(message, type) {
  if (!isMobile) {
    return ElMessageBox.confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: type
    })
  } else {
    return Dialog.confirm({
      title: '提示',
      message: message
    })
  }
}
