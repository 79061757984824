import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['el-form', _ctx.theme])
  }, [
    _createVNode(_component_el_input, {
      maxlength: "20",
      modelValue: _ctx.input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
      placeholder: "请输入职位关键词",
      class: "s-input",
      onKeydown: _withKeys(_ctx.handleInputChange, ["enter"])
    }, {
      append: _withCtx(() => [
        _createVNode(_component_el_button, { onClick: _ctx.handleInputChange }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_Search)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "onKeydown"])
  ], 2))
}