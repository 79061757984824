
import story from './story.vue'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  components: {
    story
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    console.log('route', route, router)
    const state = reactive({
      activeName: route.query.tab
    })
    const handleClick = () => {
      // 记录当前tab，处理默认路由返回时问题
      const query = {
        ...route.query,
        tab: state.activeName
      }
      router.replace({
        path: '/',
        query
      })
    }
    return {
      ...toRefs(state),
      handleClick
    }
  }
})
