/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-09 09:11:09
 * @LastEditTime: 2023-03-06 10:16:30
 */
/**
 * 全局变量、系统配置。注册到vue里
 */
// 首页配置
const homeEnum = [
  // {
  //   key: 'company',
  //   value: '中通生态',
  //   name: 'IZHONG TONG ECOLOGYT'
  // },
  {
    key: 'position',
    value: '搜索你的职位',
    name: 'YOUR POSITION'
  },
  {
    key: 'life',
    value: '中通生活',
    name: 'ZHONG TONG LIFE'
  },
  {
    key: 'story',
    value: '员工故事',
    name: 'ZHONG TONG STORY'
  }
]

// 中通生态
const companyEnum = [
  {
    key: '中通快递',
    searchValue: '中通快递',
    value:
      '中通快递创建于2002年5月8日，是一家以快递为核心业务，集跨境、快运、云仓、冷链、金融、商业（兔喜生活）等生态版块于一体的综合物流服务企业。2016年10月27日在美国纽约证券交易所上市，向全世界打开了一扇了解中国快递发展的窗口；2020年9月29日在香港上市，成为首家同时在美国、中国香港两地上市的快递企业。'
  },
  {
    key: '中通快运',
    searchValue: '中通快运',
    value:
      '中通快运成立于2016年，是中通品牌旗下快运企业。公司采用“中心直营+网点加盟”的扁平化管理模式，布局全国物流枢纽和末端加盟网络围绕物联网、大数据、云计算、人工智能，积极探寻物流业与制造业、服务业融合发展的模式，提供面向企业及个人客户的全链路一站式物流服务，聚焦智慧物流新趋势，公司持续提升数字科技渗透率，自主开发TM5汽运平台、0A平台、数据驾驶舱、网点版系统、培训平台等，并成立浙江公链信息科技有限公司，专注数字化、智能化产品研发，致力于打造“科技引领、数据支撑、人才保证、智慧运营”的综合型物流服务平台。'
  },
  {
    key: '中通云仓',
    searchValue: '中通云仓',
    value:
      '中通云仓科技是一家为品牌商、经销商、线下门店、电商平台等客户提供全渠道物流供应链服务企业，是知名的全国性云仓企业，国家高新技术企业，4A级物流企业。目前，公司在全国已成立70多家子公司，拥有仓库数量近300个，仓储总面积超210万平方米，配送范围辐射全国99%区县和95%乡镇，累计服务数千家电商平台和品牌客户。'
  },
  {
    key: '中通国际',
    searchValue: '中通国际',
    value:
      '中通国际是中通快递集团的国际窗口，自2014年成立以来，始终围绕中通快递集团“成为全球一流的综合物流服务商”的发展目标，依托强大的地面服务网络与全球资源整合能力，打造以快递为核心的综合物流服务体系，通过整合海外仓、海外专线、跨境电商供应链，建立“多渠道、多元化、全方位”的服务模式，推动产品国际化、服务全球化，提供定制化的一站式物流供应链服务。'
  },
  {
    key: '兔喜生活',
    searchValue: '兔喜',
    value:
      '免喜生活以线下门店为根基，打造“快递包裹+社区零售”的经营模式，探索驿站的全新形态。借助中通成熟的物流体系，免喜生活畅通工业品下乡和农产品上行渠道，直连全国好物货源，让用户在家门口就能买到物美价优的好物。在线上，免喜生活为用户提供本地生活服务，让用户“吃、喝、玩、乐”简单便捷。免喜生活还推出了兔喜臻选、免嗨淘等线上商城，分别提供日常消费产品与全球尖货，充分满足不同用户多元的购物需求。'
  },
  {
    key: '中通冷链',
    searchValue: '中通冷链',
    value:
      '中通冷链是中通快递集团旗下冷链品牌，成立于 2020 年，以直营+加盟为组织模式,建立了 BC 一体化、仓干配一体化的全国性综合冷链物流网络。中通冷链秉持“同建共享，信任责任”的核心价值观，致力于通过持续的基础设施建设、先进设施设备和信息技术的应用以及网络化组织模式的建立，实现全程不断链、全程温控可视、全程信息可追溯，在食品安全、行业标准、供应链效能、价值创造等方面推动流通模式的创新与变革，为市场提供从田间到餐桌的全场景全链路 BC一体化冷链物流服务。目前业务包括冷链快运、冷链快递、冷链云仓、航空速运。'
  }
]

// 职位类别
const posTypeEnum = [
  {
    key: 'Software',
    value: '软件工程师',
    positionType: 'it-02'
  },
  {
    key: 'Product',
    value: '产品经理',
    positionType: 'it-04'
  },
  {
    key: 'Test',
    value: '质量工程师',
    positionType: 'it-06'
  },
  {
    key: 'Designer',
    value: '设计师',
    positionType: 'it-09'
  },
  {
    key: 'Operation',
    value: '运营网管类',
    positionType: '22'
  },
  {
    key: 'Marketing',
    value: '市场营销类',
    positionType: '29'
  },
  {
    key: 'Service',
    value: '服务质量类',
    positionType: '23'
  }
]

// 隐藏搜索框
const showSearchComponents = ['PositionDetail']

// 社招校招
const resumeTypeEnum = {
  socialEnum: [
    {
      key: 'educationExperiences',
      value: '教育经历',
      image: require('@/assets/h5/icon-educationExperiences.png'),
      name: 'schoolName',
      isComplate: false
    },
    {
      key: 'workExperiences',
      value: '工作经历',
      image: require('@/assets/h5/icon-workExperiences.png'),
      name: 'companyName',
      isComplate: false
    },
    {
      key: 'projectExperiences',
      value: '项目经验',
      image: require('@/assets/h5/icon-workExperiences.png'),
      name: 'projectName',
      isComplate: true
    }
  ],
  campusEnum: [
    {
      key: 'educationExperiences',
      value: '教育经历',
      image: require('@/assets/h5/icon-educationExperiences.png'),
      name: 'schoolName',
      isComplate: false
    },
    {
      key: 'practiceExperienceVoList',
      value: '实习经历',
      image: require('@/assets/h5/icon-workExperiences.png'),
      name: 'practiceCompanyName',
      isComplate: true
    },
    {
      key: 'projectExperiences',
      value: '项目经验',
      image: require('@/assets/h5/icon-workExperiences.png'),
      name: 'projectName',
      isComplate: true
    },
    {
      key: 'schoolOfficeVoList',
      value: '在校职务',
      image: require('@/assets/h5/icon-educationExperiences.png'),
      name: 'cadreName',
      isComplate: true
    }
  ],
  othersEnum: [
    {
      key: 'personalWorksList',
      value: '作品',
      name: 'worksUrl'
    },
    {
      key: 'awardsWonsVoList',
      value: '获奖经历',
      name: 'awardName'
    },
    {
      key: 'languageAbilityVoList',
      value: '语言',
      name: 'languageTypeValue'
    }
  ]
}

const recruiteTypeEnum = {
  social: 1,
  campus: 2,
  CampusPosition: 2,
  blue: 3
}

const recruiteType2String = {
  1: '社会招聘',
  2: '校园招聘',
  3: '一线招聘'
}

export default {
  homeEnum,
  companyEnum,
  posTypeEnum,
  showSearchComponents,
  resumeTypeEnum,
  recruiteTypeEnum,
  recruiteType2String
}
