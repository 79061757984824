<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-04-06 18:40:20
 * @LastEditTime: 2022-04-06 20:33:23
-->
<template>
  <div class="z-loading-mask is-fullscreen">
    <div>
      <img src="@/assets/h5/loading.gif" width="70" />
      {{ text || '加载中...' }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String
    }
  }
})
</script>

<style lang="scss" scoped>
.z-loading-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2022;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8bfc9;
  div {
    text-align: center;
    font-size: 12px;
  }
  img {
    display: block;
  }
  &.is-fullscreen {
    position: fixed;
  }
}
</style>
