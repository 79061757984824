<template>
  <div class="content" v-if="!$isMobile">
    <!-- 中通生活组件 -->
    <div
      class="card"
      :class="{ blur: touchTab, active: !touchTab }"
      @mouseover="touchTab = true"
      @mouseout="touchTab = false"
      id="life"
    >
      <div class="bg" :style="{ backgroundImage: `url(${activeTabDetail.photoUrl})` }"></div>
      <div class="card_left" :style="{ background: activeTabDetail.opcolor }">
        <div class="card_title">
          <div class="card_name">{{ activeTabDetail.name }}</div>
          <div class="card_Ename">{{ activeTabDetail.filterName }}</div>
        </div>
      </div>
      <!--  :style="{ background: activeTabDetail.opcolor1 }"-->
      <div class="popup" :data-color="activeTabDetail.colorIndex">
        <div class="popup_content">
          <div class="popup_title">
            {{ activeTabDetail.name }}
          </div>
          <div class="split">
            <div></div>
            <div></div>
          </div>
          <div class="remark">
            <span v-html="activeTabDetail.remark"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div v-for="(item, index) in lifeListDetail" :key="index" class="title">
        <span :class="['tab_name', activeTab == index ? 'active_tab' : '']" @mouseover="activeTab = index">
          <div class="active_O" v-if="activeTab == index"></div>
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
  <div v-else>
    <van-tabs v-model:active="H5ActiveTab" class="h5Tab" style="margin-top: 0.2rem" @click-tab="pushSwiper">
      <van-tab v-for="(item, index) in lifeListDetail" :key="index" :title="item.name" :name="index"></van-tab>
    </van-tabs>
    <div class="Cline"></div>
    <div class="swiper">
      <swiper
        ref="mySwiper"
        class="h-company__swiper"
        :space-between="0.19 * fontSize"
        :slides-per-view="1.12"
        :slides-offset-before="0.42 * fontSize"
        :slides-offset-after="0.42 * fontSize"
        autoHeight
        @swiper="setSwiper"
        @slideChange="setTab"
      >
        <swiper-slide
          class="h-company__item"
          v-for="(item, index) in lifeListDetail"
          :key="index"
          :virtual-index="index"
        >
          <img class="swiper_img" :src="item.photoUrl" />
          <div class="swiper_title">
            {{ item.name }}
          </div>
          <div class="swiper_content" v-html="item.remark"></div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  onMounted,
  getCurrentInstance,
  computed,
  ref,
  onBeforeUnmount
} from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay, Virtual } from 'swiper'
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Virtual])
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    recruitingType: Number
  },
  setup(props) {
    const route = useRoute()
    const { proxy } = getCurrentInstance()
    const state = reactive({
      lifeList: [
        { value: '1', tabName: '员工关爱' },
        { value: '2', tabName: '开放环境' },
        { value: '3', tabName: '关注成长' },
        { value: '4', tabName: '热心公益' }
      ],
      lifeListDetail: [],
      activeTab: null,
      touchTab: true,
      time: null,
      activeTabDetail: {},
      active: '',
      mySwiper: null, //假的swiper实例
      H5ActiveTab: 0,
      Swiper: null //真的swiper实例
    })
    onMounted(() => {
      if (route.query.ids) {
        getPreview()
      } else {
        getlifeList()
      }
    })
    // let fontSize = computed(() => {
    //   return document
    // })
    watch(
      () => state.touchTab,
      (newVal, oldVal) => {
        if (newVal) {
          clearInterval(state.time)
        } else {
          state.time = setInterval(() => {
            if (state.activeTab >= state.lifeListDetail.length - 1) {
              state.activeTab = 0
            } else {
              state.activeTab = Number(state.activeTab) + 1
            }
          }, 3000)
        }
      },
      { deep: true }
    )
    watch(
      () => state.activeTab,
      (newVal, oldVal) => {
        // if (newVal) {
        let colorList = [
          {
            bgcolor: 'rgba(94, 141, 255, 0.8)',
            opcolor: 'rgba(94, 141, 255, 0.4)',
            colorIndex: 0
          },
          {
            bgcolor: 'rgba(143, 213, 0, 0.5)',
            opcolor: 'rgba(143, 213, 0, 0.4)',
            colorIndex: 1
          },
          {
            bgcolor: 'rgba(255, 103, 31, 0.5)',
            opcolor: 'rgba(255, 103, 31, 0.4)',
            colorIndex: 2
          },
          {
            bgcolor: 'linear-gradient(rgba(150, 140, 131, 0.6) 0%, rgba(150, 140, 131, 0.3) 100%)',
            opcolor: 'rgba(150, 140, 131, 0.4)',
            colorIndex: 3
          }
        ]
        state.lifeListDetail.forEach((item, index) => {
          if (newVal == index) {
            state.activeTabDetail = { ...item, ...colorList[index] }
          }
        })
        // }
      },
      { deep: true }
    )
    function getlifeList() {
      let data = {
        type: '1',
        recruitingType: props.recruitingType,
        pageNum: 1,
        pageSize: 4,
        status: 1
      }
      proxy.$http.campus.getPCActivityByPage(data).then((res) => {
        state.lifeListDetail = res.list
        state.touchTab = false
        state.activeTab = '0'
      })
    }
    function getPreview() {
      let data = {
        key: route.query.ids
      }
      proxy.$http.campus.getActivityStorage(data).then((res) => {
        if (res) {
          let a = document.getElementById('life')
          if (route.query.active && route.query.active == 1) {
            window.scrollTo({ top: a.offsetTop - 200, behavior: 'smooth' })
          }
          state.lifeListDetail = [JSON.parse(res)]
          state.touchTab = false
          state.activeTab = '0'
        } else {
          getlifeList()
        }
      })
    }
    const initState = () => {
      state.mySwiper = proxy.$refs.mySwiper
    }
    const fontSize = computed(() => {
      return parseInt(document.getElementsByTagName('HTML')[0].style.fontSize)
    })
    const pushSwiper = (i) => {
      state.Swiper.slideTo(i.name)
      // state.mySwiper.slideTo(3)
    }
    const setSwiper = (i) => {
      state.Swiper = i
    }
    const setTab = (i) => {
      state.H5ActiveTab = i.activeIndex
    }
    initState()
    onBeforeUnmount(() => {
      clearInterval(state.time)
    })
    return {
      ...toRefs(state),
      getlifeList,
      getPreview,
      fontSize,
      pushSwiper,
      setSwiper,
      setTab
    }
  }
})
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 389px;
  display: flex;
  .card {
    position: relative;
    width: 1039px;
    background: #e3e8ee;
    border-radius: 27px;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 1039px;
      height: 389px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.blur {
      .bg {
        filter: blur(20px);
        transition: all 0.3s ease;
      }
    }
    &.active {
      .bg {
        filter: blur(0px);
        transition: all 0.3s ease;
      }
    }

    &.bluer > .hover {
      width: 100%;
      height: 100%;
      transition: all 1s ease;
    }
    .card_left {
      position: relative;
      width: 267px;
      height: 100%;
      // border-radius: 27px 0 0 27px;
      overflow: hidden;
      z-index: 3;
    }
    .card_title {
      margin: 90px 0 0 41px;
      .card_name {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
      }
      .card_Ename {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        width: 200px;
      }
    }
    .modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 389px;
      border-radius: 27px;
    }
    .popup {
      width: 100%;
      height: 0;
      position: absolute;
      bottom: 0;
      // border-radius: 27px;
      background: transparent;
      box-sizing: border-box;
      overflow: hidden;
      z-index: 4;

      .popup_content {
        position: relative;
        width: 90%;
        height: 83%;
        margin-top: 4%;
        margin-left: 5%;
        .popup_title {
          font-size: 32px;
          font-weight: 600;
          color: #ffffff;
        }
        .split {
          margin-top: 10px;
          position: relative;
          & > div:nth-child(1) {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
          }
          & > div:nth-child(2) {
            width: calc(100% - 8px);
            padding-bottom: 1px;
            background: rgba(227, 232, 238, 1);
            position: absolute;
            top: 4px;
            left: 8px;
          }
        }
        .remark {
          padding: 30px 0;
          height: 325px;
          box-sizing: border-box;
          font-weight: 500;
          color: #ffffff;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
    &.blur > .card_left {
      opacity: 0;
      transition: all 0.3s ease;
    }
    // 如果filter加在图片上，
    // 但是想要的效果是对每个图片左侧的单一颜色设置成毛玻璃
    // 跟UI稿的颜色还是有点区别
    &.blur > .popup {
      &[data-color='0'] {
        background-image: linear-gradient(45deg, rgba(94, 141, 255, 0.5) 0%, rgba(94, 141, 255, 0.7) 100%);
      }
      &[data-color='1'] {
        background-image: linear-gradient(45deg, rgba(143, 213, 0, 0.2) 0%, rgba(143, 213, 0, 0.5) 100%);
      }
      &[data-color='2'] {
        background-image: linear-gradient(25deg, rgba(255, 103, 31, 0.4) 0%, rgba(255, 103, 31, 0.4) 100%);
      }
      &[data-color='3'] {
        background-image: linear-gradient(
          0deg,
          rgba(150, 140, 131, 0.5) 0%,
          rgba(150, 140, 131, 0.7) 70%,
          rgba(150, 140, 131, 0.8) 100%
        );
      }

      height: 100%;
      transition: all 0.5s ease;
    }
    &.active {
      .card_left {
        opacity: 1;
        transition: all 0.3s ease;
      }
      .popup {
        height: 0;
        transition: all 0.5s ease;
      }
    }
  }
  .right {
    width: 163px;
    padding-top: 25px;
    z-index: 5;
    .title {
      // display: inline-block;
      width: 100%;
      height: 80px;
      text-align: right;
      font-weight: 500;
      color: #333333;
      font-size: 21px;
      line-height: 80px;
    }
  }
  .tab_name {
    font-weight: 500;
    color: #333333;
    font-size: 19px;
    position: relative;
    .active_O {
      position: absolute;
      width: 9px;
      height: 9px;
      border: 4px solid #0092ff;
      border-radius: 50%;
      top: 10px;
      left: -26px;
    }
    // display: -webkit-box;
  }
  .active_tab {
    overflow: hidden;
    font-weight: 600;
    color: #3693ff;
    font-size: 25px;
  }
}
// .slide-fade-enter-active {
//   transition: all 0.3s ease;
// }
// .slide-fade-leave-active {
//   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .slide-fade-enter-from, .slide-fade-leave-to
// /* .slide-fade-leave-active for below version 2.1.8 */ {
//   // transform: translateY(-100px);
//   height: 0;
// }
// .slide-fade-enter-to, .slide-fade-leave-from
// /* .slide-fade-leave-active for below version 2.1.8 */ {
//   // transform: translateY(-100px);
//   height: 389px;
// }
.swiper {
  width: 100%;
  // height: 5.88rem;
  // background: #ffffff;
  // box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  // overflow: hidden;
  margin-top: 0.33rem;
  // transform: translate3d(0.33rem, 0px, 0px);
  .h-company__swiper {
    width: 100%;
    // height: 5.88rem;
  }
  .h-company__item {
    text-align: center;
    width: 6.67rem !important;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
    padding: 0.33rem 0.33rem 0.44rem;
    .swiper_img {
      cursor: pointer;
      width: 6rem;
      margin: 0 auto;
      height: 2rem;
      position: relative;
      border-radius: 0.25rem;
    }
    .swiper_title {
      margin-top: 0.25rem;
      font-size: 0.33rem;
      font-weight: 500;
      color: #000000;
      line-height: 0.42rem;
      text-align: left;
    }
    .swiper_content {
      width: 100%;
      // height: 2.19rem;
      overflow: hidden;
      font-size: 0.29rem;
      font-weight: 400;
      color: #666666;
      text-align: left;
      margin-top: 0.2rem;
      line-height: 0.44rem;
      :v-deep p {
        height: 100%;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    .h-company-item__name {
      width: 100%;
      color: #333333;
      font-size: 19px;
      position: absolute;
      top: 130px;
    }
  }
  ::v-deep .swiper-container {
    overflow: inherit;
  }
}
.h5Tab {
  &:deep(.van-tabs__nav) {
    background: inherit !important;
  }
  &:deep(.van-tabs__line) {
    width: 1.38rem;
    height: 0.04rem;
    background: #57a4ff;
    border-radius: 0.33rem;
  }
  &:deep(.van-tab__text) {
    font-size: 0.29rem;
    color: #333333;
  }
  &:deep(.van-tab--active) {
    .van-tab__text {
      font-weight: 500;
      color: #10a5f9;
    }
  }
}
.Cline {
  width: 7.15rem;
  height: 0.04rem;
  background: #e7ebf0;
  border-radius: 0.33rem;
  margin: -1px auto 0;
}

// 自适应高度
.swiper-slide {
  height: 1px;
}
.swiper-slide-active {
  height: auto;
}
</style>
