/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-22 11:17:53
 * @LastEditTime: 2022-03-02 15:35:17
 */
export default {
  getActivityByPage: {
    url: 'website/activity/getActivityByPage',
    method: 'post'
  },
  getRecruitmentNews: {
    url: 'website/activity/getRecruitmentNews',
    method: 'post'
  },
  getActivityStorage: {
    url: 'website/activity/getActivityStorage',
    method: 'post'
  },
  getPCActivityByPage: {
    url: 'website/activity/getPCActivityByPage',
    method: 'post'
  },
  // 获取首页视频位
  getFrontPageList: {
    url: 'website/activity/getFrontPageList',
    method: 'post'
  },
  getActivityInfo: {
    url: 'website/activity/getActivityInfo',
    method: 'post'
  },
  // 宣讲会预约
  appointment: {
    url: 'lecture/appointment',
    method: 'post'
  },
  // 宣讲会新预约
  appointmentNew: {
    url: 'lecture/insertLectureUser',
    method: 'post'
  },
  // 宣讲会查询职位
  selectSchoolPost: {
    url: 'postInfo/selectSchoolPost',
    method: 'post'
  },
  queryKvList: {
    url: 'basicData/queryKvList',
    method: 'post'
  }
}
