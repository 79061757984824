/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-04-06 18:58:43
 * @LastEditTime: 2022-04-07 13:14:41
 */
import loading from '@/plugins/loading'

export default {
  install: (app) => {
    app.config.globalProperties.$loading = loading

    app.directive('fixed', {
      mounted() {
        document.body.style.cssText += 'position:fixed; width: 100%: top:0px;'
      },
      unmounted() {
        document.body.style.position = 'static'
      }
    })
  }
}
