
import {
  ref,
  defineComponent,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  toRefs,
  reactive,
  watch
} from 'vue'
import { Search } from '@element-plus/icons'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Search
  },
  props: ['theme', 'search'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()

    const { theme, search } = toRefs(props)

    const state: any = reactive({
      theme: theme.value,
      input: search.value
    })

    const handleInputChange = () => {
      emit('inputChange', state.input)
    }

    watch([props], (value) => {
      if (value) {
        state.input = value[0].search
      }
    })

    return {
      ...toRefs(state),
      handleInputChange
    }
  }
})
