/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-10 10:50:26
 * @LastEditTime: 2022-02-14 09:46:46
 */
import request from './request'
import { apiUrl } from '@/apis'

const api = {}

function initApiUrl(data: any, api: any) {
  data.forEach((item: any) => {
    if (typeof item[1].url !== 'string') {
      api[item[0]] = {}
      initApiUrl(Object.entries(item[1]), api[item[0]])
    } else {
      api[item[0]] = function (options: any) {
        const { method } = item[1]
        return request(Object.assign(item[1], method === 'get' ? { params: options } : { data: options }))
      }
    }
  })
}
initApiUrl(Object.entries(apiUrl), api)

// 业务中引用的方法：this.$api.module.接口名（小驼峰）
// Object.defineProperty(Vue.prototype, '$api', {
//   value: api
// })

export default api
