<!--
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-15 14:27:17
 * @LastEditTime: 2023-02-02 10:50:06
 * @Description: 
-->
<template>
  <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" @change="handleSwiperChange">
    <van-swipe-item v-for="(item, index) in swiperList" :key="index">
      <!-- image -->
      <div
        v-if="!item.photoUrl.includes('.mp4')"
        class="h5-banner__item"
        :style="{ backgroundImage: `url(${item.photoUrl})` }"
      ></div>

      <div v-else class="h5-banner__video">
        <div style="width: 100%; height: 100%" @click="handleVideoPlay(item, index)">
          <div class="h5-banner__item" :style="{ backgroundImage: `url(${item.mobilePhotoUrl})` }"></div>

          <!-- <div class="h5-video__bg"></div> -->
          <div v-if="!videoUrl" class="h5-video__button">
            <van-icon name="play-circle-o" />
            <!-- 立即播放 -->
          </div>
        </div>
      </div>
    </van-swipe-item>
  </van-swipe>
  <div v-if="videoUrl" class="video">
    <div class="bg" @click="handleVideoClose">
      <van-icon name="cross" />
    </div>

    <video
      :src="videoUrl"
      controls
      autoplay
      :webkit-playsinline="true"
      :x-webkit-airplay="true"
      :playsInline="true"
      :x5-playsinline="true"
      x5-video-orientation="portraint"
      id="video"
      :poster="posterUrl"
    ></video>
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref, defineProps, onActivated } from 'vue'
import { useRoute } from 'vue-router'

// route
const route = useRoute()

// this
const { proxy } = getCurrentInstance()

// props
const props = defineProps({
  activeType: String || Number
})

// state
const swiperList = ref([])
const recruitType = route.meta.recruitType

// video
let playIndex = ref(null)
let autoplay = ref(5000)
let isReset = ref(true)
let videoUrl = ref(null)
let posterUrl = ref(null)

/**
 * 获取banner
 *
 */
const getSwiperList = () => {
  const params = {
    type: '0', // (社招/校招)首页-2 (社招/校招/蓝领)职位-0
    recruitingType: recruitType,
    pageNum: 1,
    pageSize: 10,
    status: 1
  }
  proxy.$http.campus.getPCActivityByPage(params).then((res) => {
    swiperList.value = res.list
  })
}

/**
 * 视频播放
 *
 * @param {Object} item
 * @param {Number} index
 */
const handleVideoPlay = (item, index) => {
  // playIndex.value = index
  autoplay.value = null
  // isReset.value = false
  videoUrl.value = item.photoUrl

  posterUrl.value = item.mobilePhotoUrl

  // let video = document.getElementById('video')
  // video.play()
}

const handleVideoClose = () => {
  videoUrl.value = null
  posterUrl.value = null
  autoplay.value = 5000
}

const handleSwiperChange = () => {
  playIndex.value = null
  autoplay.value = 5000
}

onMounted(() => {
  getSwiperList()
})

onActivated(() => {
  isReset.value = true
})
</script>

<style lang="scss" scoped>
.my-swipe {
  .van-swipe-item {
    height: 3.5rem;
  }
}
.h5-banner__item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50%;
  cursor: pointer;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.h5-banner__video {
  width: 100%;
  height: 100%;
  position: relative;
}
.h5-video__button {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  // border-radius: 0.5rem;
  line-height: 0.8rem;
  color: #fff;
  text-align: center;
}
.h5-video__bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}
.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  video {
    position: absolute;
    width: 100%;
    height: 5rem;
    top: 50%;
    left: 0;
    margin-top: -3rem;
    background: #000;
  }
  .van-icon {
    color: #fff;
    top: 0.2rem;
    right: 0.2rem;
    font-size: 0.8rem;
    position: absolute;
  }
}
::v-deep .van-swipe__indicators {
  bottom: 0.5rem;
}
</style>
