
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  computed,
  watch,
  onBeforeUnmount
} from 'vue'
import ZTitle from '@/components/layout/z-title.vue'
import ZBanner from '@/components/banner/banner-fullscreen.vue'
import ZLife from '@/components/life/index.vue'
import ZStory from '@/components/story/story.vue'
import { ArrowRightBold } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

interface companyItem {
  name?: string
  description?: string
  img?: any
}

interface EnumOption {
  key: string
  value: string
  name?: string
  searchValue: string
}

interface stateOption {
  companyList: Array<companyItem>
  company: companyItem
  mySwiper: any
  posTypeEnum: Array<EnumOption>
  homeEnum: Array<EnumOption>
  autoSlide: boolean
  newSwiper: any
  Interval: any
  activeSilde: any
}

export default defineComponent({
  name: 'Home',
  components: {
    ZTitle,
    Swiper,
    SwiperSlide,
    ZLife,
    ZStory,
    ZBanner,
    ArrowRightBold
  },
  setup() {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()

    const state = reactive({
      companyList: [],
      company: {},
      mySwiper: null,
      posTypeEnum: [],
      homeEnum: [],
      autoSlide: false,
      newSwiper: null,
      Interval: null,
      activeSilde: 0
    }) as stateOption

    // 初始化数据
    const initState = () => {
      state.companyList = proxy?.$Global.companyEnum.map((v: EnumOption) => {
        return {
          name: v.key,
          description: v.value,
          search: v.searchValue,
          img: require(`@/assets/${v.key}.png`)
        }
      })
      state.company = state.companyList[0]
      state.mySwiper = proxy?.$refs.mySwiper
      state.posTypeEnum = proxy?.$Global.posTypeEnum
      state.homeEnum = proxy?.$Global.homeEnum
      state.autoSlide = true //开始自动播放
    }

    // 选择生态公司
    const handleCompanyClick = (item: companyItem) => {
      state.autoSlide = false
      state.activeSilde = item
    }

    const handlePositionTypeClick = (item: any) => {
      console.log('选择的职位', item)
      router.push({
        name: 'social',
        query: item.key
          ? {
              positionType: item.positionType.includes('-') ? item.positionType.split('-')[1] : item.positionType
            }
          : {}
      })
    }

    const handleSearchChange = (value: string) => {
      router.push({
        name: 'social',
        query: {
          search: value
        }
      })
    }
    watch(
      () => state.autoSlide,
      (newval) => {
        if (!proxy.$isMobile.value) {
          if (newval) {
            state.Interval = setInterval(() => {
              // console.log(state.newSwiper)
              // console.log(state.newSwiper.activeIndex, state.activeSilde)
              if (state.activeSilde >= state.companyList.length - 1) {
                state.activeSilde = 0
                state.newSwiper.slideTo(1)
              } else {
                if (state.activeSilde >= 7 && state.newSwiper.activeIndex == 0) {
                  state.newSwiper.slideTo(9)
                }
                state.activeSilde = state.activeSilde + 1
              }
            }, 5000)
          } else {
            clearInterval(state.Interval)
          }
        }
      }
    )
    watch(
      () => state.activeSilde,
      (newval) => {
        state.company = state.companyList[newval]
      }
    )
    const getSwiper = (item: any) => {
      state.newSwiper = item
    }
    initState()
    onBeforeUnmount(() => {
      clearInterval(state.Interval)
    })
    return {
      ...toRefs(state),
      handleCompanyClick,
      handlePositionTypeClick,
      handleSearchChange,
      getSwiper
    }
  }
})
