/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-14 09:29:44
 * @LastEditTime: 2024-01-10 13:58:01
 */
export default {
  // 获取字典
  getDictionaryCascade: {
    url: 'dictionary/getDictionaryCascade',
    method: 'post'
  },
  // PS字典
  queryKvList: {
    url: 'basicData/queryKvList',
    method: 'post'
  },
  // 获取省市级信息
  getRegionInfoTreeList: {
    url: 'regionInfo/getRegionInfoTreeList',
    method: 'post'
  },
  // 职位工作地点
  getWorkplace: {
    url: 'postInfo/workplace',
    method: 'post'
  },
  // url生成二维码
  url2QRcode: {
    url: 'postInfo/getQRcode',
    method: 'post'
  },
  // 微信分享
  getShareConfig: {
    url: 'wechat/shareConfig',
    method: 'post'
  },
  getByGroup: {
    url: '/dictionary/getByGroup',
    method: 'post'
  }
}
