/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-21 11:40:41
 * @LastEditTime: 2024-04-25 13:09:30
 */
export default {
  // 上传简历
  upload: {
    url: 'website/resume/upload',
    method: 'post'
  },
  // 简历解析
  parse: {
    url: 'website/resume/parse',
    method: 'post'
  },
  // 获取Token
  getToken: {
    url: 'file/getToken',
    method: 'post'
  },
  // 加密上传到DFS
  // https://fs.test.ztosys.com/UploadEncryptFile
  uploadEncryptFile: {
    url: 'UploadEncryptFile',
    method: 'post',
    urlHost: 'dfs'
  },
  getFile: {
    url: 'attachmentInfo/download',
    method: 'post'
  },
  // 获取文件
  // getFile: {// },
  // 上传文件到招聘系统
  addFile: {
    url: 'attachmentInfo/add',
    method: 'post'
  },
  // 普通上传（没用）
  // https://fs.test.ztosys.com/uploadFile
  uploadFile: {
    url: 'UploadFile',
    method: 'post',
    urlHost: 'dfs'
  },
  // 普通文件获取（没用）
  // https://fs.test.ztosys.com/uploadFile
  // GetFile: {
  //   url: 'GetFile',
  //   method: 'post',
  //   urlHost: 'dfs'
  // },
  // 文件预览
  preview: {
    url: 'attachmentInfo/preview',
    method: 'post'
  },
  //  获取简历
  getResumeInfo: {
    url: '/website/resume/info',
    method: 'post'
  },
  // 简历信息修改
  updateResumeInfo: {
    url: '/website/resume/update',
    method: 'post'
  },
  // 校验简历是否上传
  checkResumeStatu: {
    url: '/website/post/getWhetherSend',
    method: 'post'
  }
}
