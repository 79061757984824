/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-13 10:22:33
 * @LastEditTime: 2022-02-28 16:38:34
 */
export default {
  // 获取用户信息
  getUserInfo: {
    url: 'website/userInfo',
    method: 'post'
  },
  // 发送短信
  smsSend: {
    url: 'website/sms/send',
    method: 'post'
  },
  // 短信登录
  smsLogin: {
    url: 'website/sms/login',
    method: 'post'
  },
  // 微信登录
  wxLogin: {
    url: 'website/wechat/login',
    method: 'post'
  },
  // 获取验证码
  getVerificationImg: {
    url: '/website/code/get',
    methods: 'post'
  }
}
