<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-18 09:59:47
 * @LastEditTime: 2023-02-13 15:33:53
-->
<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" v-if="route.meta.keepAlive" :key="$route.fullPath" />
    </keep-alive>
    <component :is="Component" v-if="!route.meta.keepAlive" :key="$route.fullPath" />
  </router-view>
</template>
