/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-11 16:49:07
 * @LastEditTime: 2024-03-25 15:18:15
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/home-management/index.vue'
import Store from '@/store'

// 百度统计代码
if ((window as any)._hmt) (window as any)._hmt.push(['_setAutoPageview', false])
function baiduTrackPageview(to) {
  if (to.path) {
    const str = to.fullPath
    if ((window as any)._hmt) (window as any)._hmt.push(['_trackPageview', str])
  }
}

// 重定向到https
if (window.location.host === 'hr.zto.com' && window.location.protocol === 'http:') {
  window.location.href = `https://hr.zto.com${window.location.pathname}`
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '中通集团招聘官网',
      recruitType: 1
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login-management/index.vue'),
    meta: {
      title: '中通招聘|登录'
    }
  },
  {
    path: '/social',
    name: 'social',
    meta: {
      keepAlive: true,
      title: '社会招聘',
      recruitType: 1,
      scrollTop: 0
    },
    component: () => import(/* webpackChunkName: "social" */ '@/views/social-management/index.vue')
  },
  {
    path: '/position-detail',
    name: 'PositionDetail',
    component: () => import(/* webpackChunkName: "PositionDetail" */ '@/views/position-detail/index.vue'),
    meta: {
      title: '职位详情'
    }
  },
  {
    path: '/campus',
    name: 'campus',
    component: () => import(/* webpackChunkName: "campus" */ '@/views/campus-management/index.vue'),
    meta: {
      title: '校园招聘',
      recruitType: 2
    }
  },
  {
    path: '/campus-position',
    name: 'CampusPosition',
    meta: {
      keepAlive: true,
      title: '校招职位',
      recruitType: 2
    },
    component: () => import(/* webpackChunkName: "campus-position" */ '@/views/campus-management/campus-position.vue')
  },
  {
    path: '/storyDetail',
    name: 'storyDetail',
    component: () => import(/* webpackChunkName: "404" */ '@/components/story/storyDetail.vue'),
    meta: {
      title: '员工故事'
    }
  },
  {
    path: '/ecologyDetail',
    name: 'ecologyDetail',
    component: () => import(/* webpackChunkName: "404" */ '@/views/campus-management/ecologyDetail.vue'),
    meta: {
      title: '招聘动态',
      recruitType: 2
    }
  },
  {
    path: '/blue',
    name: 'blue',
    meta: {
      keepAlive: true,
      title: '一线招聘',
      recruitType: 3
    },
    component: () => import(/* webpackChunkName: "blue" */ '@/views/blue-management/index.vue')
  },
  {
    path: '/myLike',
    name: 'myLike',
    meta: {
      keepAlive: true,
      needLogin: true,
      title: '我的收藏'
    },
    component: () => import(/* webpackChunkName: "myLike" */ '@/views/my-management/myLike.vue')
  },
  {
    path: '/myApply',
    name: 'myApply',
    meta: {
      keepAlive: true,
      needLogin: true,
      title: '我的申请'
    },
    component: () => import(/* webpackChunkName: "myApply" */ '@/views/my-management/myApply.vue')
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      title: '我的',
      needLogin: true
    },
    component: () => import(/* webpackChunkName: "myResume" */ '@/views/my-management/my.vue')
  },
  {
    path: '/myResume',
    name: 'myResume',
    meta: {
      title: '我的简历'
      // needLogin: true
    },
    component: () => import(/* webpackChunkName: "myResume" */ '@/views/my-management/myResume.vue')
  },
  {
    path: '/myResumeEdit',
    name: 'myResumeEdit',
    component: () => import(/* webpackChunkName: "myResume" */ '@/views/my-management/h5-my-resume-edit.vue'),
    meta: {
      title: '编辑简历'
      // needLogin: true
    }
  },
  {
    path: '/lecture',
    name: 'lecture',
    component: () => import(/* webpackChunkName: "lecture" */ '@/views/campus-management/lecture.vue'),
    meta: {
      title: '宣讲会',
      recruitType: 2
    }
  },
  {
    path: '/myResumeLoginFree',
    name: 'myResumeLoginFree',
    component: () => import(/* webpackChunkName: "myResumeLoginFree" */ '@/views/my-management/myFreeResume.vue'),
    meta: {
      title: '我的简历',
      needLogin: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/error/404.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
// 全局前置守卫 beforeEach
router.beforeEach((to, from, next) => {
  console.log('to', to.meta.title, from)
  // 处理小程序跳转时的navigationBarTitleText
  if (to.query && to.query.type && to.query.title) {
    to.meta.title = to.query.title
  }
  console.log('to', to.meta.title, from)
  // 未登录跳转登录
  if (to.meta.needLogin && !localStorage.getItem('Authorization')) {
    Store.dispatch('setRedirectUrl', to.fullPath)
    next({ path: `/login` })
  } else if (to.name == 'Login' && localStorage.getItem('Authorization')) {
    next('/')
  } else {
    if (to.meta.title) {
      ;(document.title as unknown) = to.meta.title
    }
    // 采用异步的方式,避免影响路由页面的加载
    setTimeout(() => {
      baiduTrackPageview(to)
    }, 0)
    next()
  }
})

export default router
