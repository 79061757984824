/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-10 10:34:37
 * @LastEditTime: 2022-11-17 13:05:28
 */
import auth from './auth'
import base from './base'
import position from './position'
import resume from './resume'
import campus from './campus'
import blue from './blue'

export const apiUrl = {
  auth,
  base,
  position,
  resume,
  campus,
  blue
}
