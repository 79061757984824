/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-14 14:42:28
 * @LastEditTime: 2022-12-12 13:22:00
 */
export default {
  // 职位类型列表获取
  getDictionaryCascade: {
    url: '/website/post/getDictionaryCascade',
    method: 'post'
  },
  // 查询职位列表
  getPostInfoPageList: {
    url: '/website/post/getPostInfoPageList',
    method: 'post'
  },
  // 查询收藏岗位列表
  getCollectPostInfoPageList: {
    url: '/website/post/getCollectPostInfoPageList',
    method: 'post'
  },
  // 查询岗位详情
  getPostInfoDetail: {
    url: '/website/post/getPostInfoDetail',
    method: 'post'
  },
  // 取消/收藏岗位
  cancelOrCollectPost: {
    url: '/website/post/cancelOrCollectPost',
    method: 'post'
  },
  // 查询岗位推荐列表
  getRecommendPostList: {
    url: '/website/post/getRecommendPostList',
    method: 'post'
  },
  // 收藏列表里的岗位推荐列表
  getCollectionListRecommendPostList: {
    url: '/website/post/getCollectionListRecommendPostList',
    method: 'post'
  },
  // 投递简历
  sendResume: {
    url: '/website/post/sendResume',
    method: 'post'
  },
  // 查询我的投递
  getMyApplication: {
    url: '/website/post/getMyApplication',
    method: 'post'
  },
  // 获取工作地点
  getWorkPlace: {
    url: '/postInfo/workplace',
    method: 'post'
  },
  // 一线简历投递
  blueDelivery: {
    url: '/website/blue/delivery',
    method: 'post'
  },
  checkDelivery: {
    url: '/website/checkDelivery',
    method: 'post'
  }
}
