
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    function handleClick(url) {
      window.open(url, '_blank')
    }
    return {
      handleClick
    }
  }
})
