/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-04-06 18:40:27
 * @LastEditTime: 2022-04-07 17:26:03
 */
import { createApp, h, defineComponent, createVNode } from 'vue'

import Loading from './Loading.vue'

export default {
  instance: null,
  parent: null,
  timer: 0,
  show(text) {
    if (this.timer == 0 && !this.parent) {
      const myApp = defineComponent({
        render() {
          return h(Loading, { text })
        }
      })
      this.instance = createApp(myApp)
      this.parent = document.createElement('div')
      document.body.appendChild(this.parent)
      this.instance.mount(this.parent)
    }
    this.timer++
  },
  hide() {
    this.timer--
    if (this.timer <= 0 && this.parent) {
      this.timer = 0
      document.body.removeChild(this.parent)
      this.instance.unmount(this.parent)
      this.parent = null
    }
  }
}
