
import { defineComponent, reactive, ref, toRefs, getCurrentInstance, ComponentInternalInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ZSearch from '@/components/layout/z-search.vue'
import store from '@/store'

interface stateOption {
  bgUrl: string
  bgStyle: string
  bgSlogan: string
  poster: string
}

export default defineComponent({
  components: {
    ZSearch
  },
  props: ['recruitingType'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const route = useRoute()
    const { recruitingType } = toRefs(props)
    const videoRef = ref(null)

    const state = reactive({
      bgUrl: '',
      bgStyle: '',
      bgSlogan: ``,
      poster: ``
    }) as stateOption

    // 判断文件类型
    const judgeBgStyle = () => {
      if (state.bgUrl.includes('.mp4') || state.bgUrl.includes('.mpv')) {
        state.bgStyle = 'video'
      } else {
        state.bgStyle = 'image'
      }
    }

    // 获取Banner
    const getBanner = () => {
      const params = {
        type: '5',
        recruitingType: recruitingType.value
      }
      proxy.$http.campus.getFrontPageList(params).then((res: any) => {
        if (res && res.length) {
          const data = res[res.length - 1]
          const { name: bgSlogan, photoUrl: bgUrl, filterName: poster } = data

          state.bgSlogan = bgSlogan
          state.bgUrl = bgUrl
          state.poster = poster

          videoRef.value.play()
          // judgeBgStyle()
        }
      })
    }

    const getPreviewBanner = () => {
      let data = {
        key: route.query.ids
      }
      proxy.$http.campus.getActivityStorage(data).then((res: any) => {
        if (res) {
          let result = JSON.parse(res)
          if (result.name) {
            state.bgSlogan = result.name
          }
          if (result.photoUrl) {
            state.bgUrl = result.photoUrl
            // judgeBgStyle()
          }
        } else {
          getBanner()
        }
      })
    }

    if (route.query.ids && route.query.activeName == '5') {
      getPreviewBanner()
    } else {
      getBanner()
    }

    // 搜索
    const handleSearchChange = (value: string) => {
      router.push({
        name: recruitingType.value == 2 ? 'CampusPosition' : 'social',
        query: {
          search: value
        }
      })
    }

    return {
      ...toRefs(state),
      handleSearchChange,
      videoRef
    }
  }
})
